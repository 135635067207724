import React from "react"
import styles from "./cards.module.scss"
const featuresPricingCards = ({ title, subtitle, withMargins, freeOpti, freeMini }) => (
  <div className={withMargins ? styles.wrap : ''}>
    <div className="container">
      {title ?
        <div className={styles.head}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        : ''
      }
      <div className={styles.cards}>
        <div className={`${styles.card} ${styles.green}`}>
          <p className={styles.text}>Мини</p>
          {freeMini
            ? <p className={styles.price}>бесплатно</p>
            : <p className={styles.price}>недоступно</p>
          }
          <a className={styles.link} href="/pricing">Подробнее</a>
        </div>
        <div className={`${styles.card} ${styles.blue}`}>
          <p className={styles.text}>Опти</p>
          {freeOpti
            ? <p className={styles.price}>бесплатно</p>
            : <p className={styles.price}>недоступно</p>
          }
          <a className={styles.link} href="/pricing">Подробнее</a>
        </div>
        <div className={`${styles.card} ${styles.orange}`}>
          <div className={styles.detailPrice__popular}>
            <img className={styles.detailPrice__popularIcon} src={require("../../../../images/3dfire.svg")} alt="" />
            Популярно
          </div>
          <p className={styles.text}>Макси</p>
          <p className={styles.price}>бесплатно</p>
          <a className={styles.link} href="/pricing">Подробнее</a>
        </div>
        <div className={`${styles.card} ${styles.purple}`}>
          <p className={styles.text}>Франшизы</p>
          <p className={styles.price}>бесплатно</p>
          <a className={styles.link} href="/franchise">Подробнее</a>
        </div>
      </div>
      <p className={styles.prompt}>При покупке для использования за пределами РФ может включать дополнительные налоги страны лицензиата.</p>
    </div>
  </div>
)

export default featuresPricingCards