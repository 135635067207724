import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import FeaturesGridComponent from "../../components/pages/features/grid"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeaturesPricingCards from "../../components/pages/features/cards"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeatureAccordion from "../../components/pages/main/accordion"
import styles from "./feature.module.scss"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const messengersAccordionData = [
  {
    name: "integration",
    title: "Как интеграция мессенджеров с CRM помогает увеличивать продажи?",
    content: `
      <p>Такой союз обеспечивает индивидуальный подход и своевременную обратную связь, что укрепляет доверие клиентов. Благодаря этому покупатели охотнее совершают покупки, что приводит к росту среднего чека и увеличению прибыли. </p>
    `,
  },
  {
    name: "types",
    title: "Какие мессенджеры можно подключить в Параплан CRM?",
    content: `
      <p>Возможна интеграция мессенджеров Whatsapp и Telegram с CRM-системой Параплан.</p>
    `,
  },
  {
    name: "connection",
    title: "Как подключить к CRM мессенджеры?",
    content: `
      <p>Telegram и WhatsApp подключаются к Параплан через сервис <a rel="nofollow" href="https://teletype.app/?promo=YBVI4F&utm_source=email&utm_medium=link&utm_campaign=affiliate">Teletype</a>. Для интеграции мессенджеров с CRM вам нужно сначала зарегистрироваться в <a rel="nofollow" href="https://teletype.app/?promo=YBVI4F&utm_source=email&utm_medium=link&utm_campaign=affiliate">Teletype</a> и оплатить тариф (дополнительно внести оплату за <strong>Публичное API</strong>). Затем отправить токен технической поддержке Параплан CRM. В обратном письме вы получите <strong>Webhook</strong>. Его нужно будет скопировать и вставить в поле <strong>Webhook</strong> в разделе <strong>Настройки</strong> во вкладке <strong>Публичное API</strong>. Не забудьте сохранить.</p>
      <p>Если возникли трудности с подключением в CRM мессенджеров, вы можете обратиться к подробным инструкциям в <a rel="nofollow" href="https://paraplancrm.ru/knowledgebase/">Базе знаний</a>.</p>
    `,
  }
]

const MessengersFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Мессенджеры', url: ''}
  ];
  return (
    <Layout className={styles.clients} headerColor={"#F2A819"} page={"messengers"}>
      <SEO
        title="Используйте мессенджеры Whatsapp и Telegram в CRM Параплан"
        keywords={[`учет клиентов`, `мессенджеры`, `список учеников`]}
        description="Воспользуйтесь интеграцией CRM с мессенджерами WhatsApp и Telegram. Общайтесь с клиентами, лидами и сотрудниками, не покидая из CRM-систему."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"messengers"}
        title='Интеграция мессенджеров с CRM-системой'
        description='Переписывайтесь с клиентами, лидами через мессенджеры в одном окне CRM-системы. Меньше переключений между программами, больше продаж.'
        color='#F2A819'
        btnDark={true}
        image={require("../../images/cover/messangers.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"yellow"}/>
      <AboutComponent
        title='Внутренний чат'
        description='Переписывайтесь с группами и отдельными клиентами в чате CRM-системы. Чат обновляется онлайн и заменяет приложения WhatsApp и Telegram. Клиент не берет трубку - начните общение в мессенджере.'
        image={require("../../images/screenshot/messangers/inner-chat.png")}
        imageWidth={676}
        imageHeight={480}
        bgSize={"clear"}
        reverse={true}
        color='#03C4A4' />
      <AboutComponent
        title='Переключатель <br/> мессенджеров'
        description='Если у вас подключена интеграция мессенджеров Whatsapp и Telegram с CRM-системой, то вы сможете общаться в одном окне сразу в двух приложениях. Исключены переключения между программами. Искать, в какой именно мессенджер вы ранее написали клиенту, не нужно. Вся история переписки хранится в едином окне системы.'
        image={require("../../images/screenshot/messangers/switcher-chats.png")}
        imageWidth={677}
        imageHeight={374}
        bgSize={"position-bottom"}
        color='#03C4A4' />
      <Discount />
      <AboutComponent
        title='История <br/> переписки'
        description='Просматривайте историю сообщений для контроля вашего менеджера и построения эффективного диалога с клиентом. Лиды и клиенты общаются привычным способом, при этом переписка дублируется в их профилях.'
        image={require("../../images/screenshot/messangers/history.png")}
        imageWidth={676}
        imageHeight={480}
        bgSize={"clear"}
        color='#03C4A4' />
      <AboutComponent
        title='Уведомления в системе'
        description='Сокращайте время ответа на сообщения благодаря уведомлениям в системе. Быстрое реагирование на запросы поможет больше продавать и удерживать клиентов центра. Сервис становится лучше, а работа - проще.'
        image={require("../../images/screenshot/messangers/notification.png")}
        imageWidth={676}
        imageHeight={480}
        bgSize={"clear"}
        reverse={true}
        color='#03C4A4' />
      <FeaturesGridComponent
        title="Интеграция мессенджеров с CRM-системой поможет </br> контролировать коммуникацию с клиентами"
        left={true}
        threeItems={true}
        features={[
          {
            name: "Защитите данные",
            text: "Все сотрудники центра общаются с клиентом или лидом с одного номера компании. Сотрудник не сможет вести переписку с личного номера. Все контакты хранятся только в CRM.",
            image: require("../../images/screenshot/messangers/security.svg"),
          },
          {
            name: "Унифицируйте общение",
            text: "Руководитель компании контролирует время ответа, стиль общения менеджера, корректность информации. Клиенты названы единообразно, это позволяет избежать путаницы или повторов в контактах.",
            image: require("../../images/screenshot/messangers/time.svg"),
          },
          {
            name: "Пишите первыми",
            text: "Менеджерам и администраторам не нужно запоминать, в каком именно мессенджере удобно общаться клиенту. В едином окне системы сохраняется переписка сразу из двух мессенджеров.",
            image: require("../../images/screenshot/messangers/feedback.svg"),
          },
        ]} />
      <FeaturesPricingCards
        freeOpti={true}
        withMargins={true}
        title='Работайте с клиентами на их территории!'
        subtitle='Стоимость интеграции с мессенджерами Telegram и WhatsApp'
      ></FeaturesPricingCards>
      <FeaturesComponent title={'Дополнительные возможности'} />
      <FeatureAccordion
            data={messengersAccordionData}
            title='Воспользуйтесь интеграцией мессенджеров с CRM-системой Параплан'
            descr='В Параплан CRM подключена интеграция с мессенджерами. Через единое окно в системе менеджеры могут общаться с клиентами с одного номера, не используя личный. Руководитель с легкостью проконтролирует время и корректность ответа сотрудника в мессенджерах. Администраторам не нужно запоминать, где удобно общаться клиенту, переписка сохраняется как из Telegram, так из WhatsApp. CRM с рассылкой в мессенджеры удобна скоростью оповещения клиентов об изменениях без переключений между приложениями. Интеграция мессенджеров с CRM избавит бизнес от путаницы и длительных поисков нужного диалога.'
      />
      <ContactFormComponent />
    </Layout>
  )
}

export default MessengersFeaturePage
